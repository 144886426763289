var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ck-text-input", {
        attrs: {
          value: _vm.name,
          id: "name",
          label: "Organisation name",
          type: "text",
          error: _vm.errors.get("name")
        },
        on: {
          input: function($event) {
            return _vm.onInput("name", $event)
          }
        }
      }),
      _vm.auth.isGlobalAdmin
        ? _c(
            "ck-text-input",
            {
              attrs: {
                value: _vm.slug,
                id: "slug",
                label: "Unique slug",
                type: "text",
                error: _vm.errors.get("slug")
              },
              on: {
                input: function($event) {
                  return _vm.onInput("slug", $event)
                }
              }
            },
            [
              _c(
                "gov-hint",
                { attrs: { slot: "hint", for: "slug" }, slot: "hint" },
                [
                  _vm._v(
                    "\n      This will be used to access the organisation page."
                  ),
                  _c("br"),
                  _vm._v(
                    "\n      e.g. example.com/organisations/" +
                      _vm._s(_vm.slug) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("ck-wysiwyg-input", {
        attrs: {
          value: _vm.description,
          id: "description",
          maxlength: 3000,
          label: "Please provide a one-line summary of organisation",
          hint:
            "This should be a short line or two that summarises who the organisation is and will appear below the Organisation name on it's page.",
          error: _vm.errors.get("description")
        },
        on: {
          input: function($event) {
            return _vm.onInput("description", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.url,
          id: "url",
          label: "Organisation website address",
          type: "url",
          error: _vm.errors.get("url")
        },
        on: {
          input: function($event) {
            return _vm.onInput("url", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.phone,
          id: "phone",
          label: "Public phone",
          type: "tel",
          error: _vm.errors.get("phone")
        },
        on: {
          input: function($event) {
            return _vm.onInput("phone", $event)
          }
        }
      }),
      _c("ck-text-input", {
        attrs: {
          value: _vm.email,
          id: "email",
          label: "Public email address",
          type: "email",
          error: _vm.errors.get("email")
        },
        on: {
          input: function($event) {
            return _vm.onInput("email", $event)
          }
        }
      }),
      _c("ck-image-input", {
        attrs: {
          id: "logo",
          label: "Organisation logo",
          "file-id": _vm.logo_file_id,
          errors: _vm.errors
        },
        on: {
          input: function($event) {
            return _vm.onInput("logo_file_id", $event.file_id)
          },
          "image-changed": function($event) {
            return _vm.$emit("image-changed", $event)
          },
          "alt-text-changed": function($event) {
            return _vm.$emit("alt-text-changed", $event)
          }
        }
      }),
      _c("gov-section-break", { attrs: { size: "l" } }),
      _c("gov-heading", { attrs: { size: "m" } }, [
        _vm._v("Social media links")
      ]),
      _c("gov-body", [
        _vm._v(
          "\n    If you have any social media accounts for your Organisation, please select\n    the appropriate platform from the dropdown and add the relevant URL.\n  "
        )
      ]),
      _c("ck-social-medias-input", {
        attrs: { "social-medias": _vm.social_medias, errors: _vm.errors },
        on: {
          input: function($event) {
            return _vm.$emit("update:social_medias", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }